import './Index.css';
import Image from '../components/Image';


export default function Index() {
  return  <div className="App">
    <header className="App-header">
      <h1>Hello World!</h1>
      <Image />
    </header>
  </div>
  }